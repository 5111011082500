.searchPage {
  // padding: 0px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  animation-name: opacity;
  flex: 1;
  .mainTitle {
    font-size: 30px;
    font-weight: 600;
    transition: 0.3s;
    overflow: hidden;
    height: 70px;
    min-height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 1;
    padding-bottom: 20px;
    &.searchActive {
      min-height: 0;
      height: 0;
      opacity: 0;
      padding-bottom: 0;
    }
  }
}

.stickeyHeader {
  // position: fixed;
  top: 54px;
  &.app {
    top: 96px;
  }
  left: 0;
  width: 100%;
  background-color: white;
  display: none;
  z-index: 1;
  padding: 0px 16px;
  opacity: 0;
  animation-duration: 0.3s;
  transform: translateY(-50%);
  &.show {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
    display: flex;
    animation-name: show;
    animation-fill-mode: forwards;
    border-bottom: thin solid #00000005;
  }
  &.hide {
    animation-name: show;
    animation-direction: reverse;
  }
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
.filtersContainer {
  overflow-y: hidden;
  display: flex;
  gap: 8px;
  flex: 1;
  padding: 4px 16px;
  // background-color: white;

  // min-height: 0px;
  // max-height: 0px;
  transition: 0.3s;
  // opacity: 0;
}
.filterPreviewList {
  display: flex;
  gap: 10px;
  align-items: center;
  overflow-x: auto;
}

.filterPreviewWrapper {
  padding: 40px 0 60px 0;
  flex: 1;
  display: flex;
  align-items: center;
}
.filterIconContainer {
  overflow: hidden;
  transition: 0.4s;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: fit-content;
  .filterIconWrapper {
    padding: 5px;
    border-radius: 50%;
    border: thin solid #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.searchView {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  .title {
    font-size: 13px;
    font-weight: 600;
  }

  .collections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    .singleCollection {
      border-radius: 15px;
      padding: 12px;
      font-size: 18px;
      font-weight: 600;
      min-height: 120px;
      max-height: 120px;
    }
  }

  .collectionList {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    gap: 50px;
    .listItem {
      padding: 25px 15px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 500;
      border-bottom: thin solid #a6a6a638;
    }
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  .title {
    font-weight: 500;
    text-transform: capitalize;
  }
  .clear {
    text-decoration: underline;
    color: grey;
    font-size: 14px;
  }
}

// ===========================

.searchPillsContainer {
  overflow: auto;
}
.searchPills {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 250%;
  max-width: 900px;
  font-size: 14px;
  font-weight: 300;
  &.move {
    animation-name: move;
    animation-duration: 400s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.searchContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.searchCategory {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-90%);
  }
  100% {
    transform: translateX(0);
  }
}

.searchView {
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow: auto;
  flex: 1;
  justify-content: flex-start;
}
