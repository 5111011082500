@import "src/styles/index.scss";
$position-for-infinite-scroll-dont-delete: relative;
.results {
  display: grid;
  gap: 8px;
  flex: 1;
  padding-bottom: 80px;
  position: $position-for-infinite-scroll-dont-delete;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: masonry;
  overflow-x: hidden;
  overflow-y: auto;

  .fetchingMore {
    position: absolute;
    bottom: 0;
  }

  // Responsive column counts
  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr); // Tablet: 3 columns
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(4, 1fr); // Desktop: 4 columns
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(5, 1fr); // Large Desktop: 5 columns
  }
}

.mobileBrand {
  font-size: 18px;
  font-weight: 600;
}

@include for-normal-layout {
  .results {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

.overrideClassName {
  max-width: unset;
}
