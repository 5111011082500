@import "src/styles/index";

.pill {
  border: thin solid rgba($color: #000000, $alpha: 0.1);
  padding: 8px 16px;
  border-radius: 50px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: white;
  line-height: 1;
  font-weight: 400;
  &.naked {
    background: transparent;
    border: thin solid var(--font-color-primary);
  }
  &.selected {
    background-color: #1f2029;
    color: white;
    border-color: transparent;
  }
  &.secondary {
    background-color: #f0f0f0;
    backdrop-filter: blur(30px);
    color: #292929;
    font-weight: 400;
    border-color: transparent;
    &.selected {
      background-color: #1f2029;
      color: white;
      * {
        path {
          fill: white;
        }
      }
    }
  }
  &.ai {
    // background: rgb(189, 213, 242);
    background: var(--ai-gradient-bg);
    border-color: white;
    color: #4a4a4a; /* Subtle, neutral text color */
  }
  &.sm {
    font-size: 12px;
    padding: 0.6em 1.5em;
    font-weight: 300;
  }
  &.md {
    font-size: 12px;
    font-weight: 300;
    padding: 10px 14px;
  }
  &.lg {
    padding: 1em 1.5em;
    font-size: 14px;
  }
  &.xlg {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 2em;
    font-size: 18px;
  }
}
