@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-variable.ttf") format("truetype");
}
@font-face {
  font-family: "playfair";
  src: url("./fonts/playfair-variable.ttf") format("truetype");
}
@font-face {
  font-family: "Cormorant Garamond";
  font-style: normal;
  src: url("./fonts/cormorant-garamond.ttf") format("truetype");
}
@font-face {
  font-family: "Cormorant Garamond";
  font-style: italic;
  src: url("./fonts/cormorant-garamond-italic.ttf") format("truetype");
}
// @font-face {
//   font-family: "Proxima Nova";
//   font-weight: 200;
//   src: url("./fonts/proximanova-thin.otf") format("opentype");
// }
// @font-face {
//   font-family: "Proxima Nova";
//   font-weight: 300;
//   src: url("./fonts/proximanova-light.otf") format("opentype");
// }
// @font-face {
//   font-family: "Proxima Nova";
//   font-weight: 400;
//   src: url("./fonts/proximanova-regular.otf") format("opentype");
// }
// @font-face {
//   font-family: "Proxima Nova";
//   font-weight: 500;
//   src: url("./fonts/proximanova-medium.otf") format("opentype");
// }
// @font-face {
//   font-family: "Proxima Nova";
//   font-weight: 600;
//   src: url("./fonts/proximanova-semibold.otf") format("opentype");
// }
// @font-face {
//   font-family: "Proxima Nova";
//   font-weight: 700;
//   src: url("./fonts/proximanova-bold.otf") format("opentype");
// }
// @font-face {
//   font-family: "Proxima Nova";
//   font-weight: 800;
//   src: url("./fonts/proximanova-black.otf") format("opentype");
// }
// @font-face {
//   font-family: "DM Sans";
//   font-weight: 200;
//   src: url("./fonts/dm-sans-latin-200-normal.woff2") format("woff2");
// }
// @font-face {
//   font-family: "DM Sans";
//   font-weight: 300;
//   src: url("./fonts/dm-sans-latin-300-normal.woff2") format("woff2");
// }
// @font-face {
//   font-family: "DM Sans";
//   font-weight: 400;
//   src: url("./fonts/dm-sans-latin-400-normal.woff2") format("woff2");
// }
// @font-face {
//   font-family: "DM Sans";
//   font-weight: 500;
//   src: url("./fonts/dm-sans-latin-500-normal.woff2") format("woff2");
// }
// @font-face {
//   font-family: "DM Sans";
//   font-weight: 600;
//   src: url("./fonts/dm-sans-latin-600-normal.woff2") format("woff2");
// }
// @font-face {
//   font-family: "DM Sans";
//   font-weight: 700;
//   src: url("./fonts/dm-sans-latin-700-normal.woff2") format("woff2");
// }
// @font-face {
//   font-family: "DM Sans";
//   font-weight: 800;
//   src: url("./fonts/dm-sans-latin-800-normal.woff2") format("woff2");
// }
// @font-face {
//   font-family: "DM Sans";
//   font-weight: 900;
//   src: url("./fonts/dm-sans-latin-900-normal.woff2") format("woff2");
// }

// // @font-face {
// //   font-family: "Helvetica";
// //   src: url("./fonts/Helvetica-Bold.ttf") format("truetype");
// //   font-weight: 600;
// // }
// // @font-face {
// //   font-family: "Helvetica";
// //   src: url("./fonts/Helvetica-Light.ttf") format("truetype");
// //   font-weight: 300;
// // }
// // @font-face {
// //   font-family: "Helvetica";
// //   src: url("./fonts/Helvetica.ttf") format("truetype");
// //   font-weight: 400;
// // }
// // @font-face {
// //   font-family: "Helvetica";
// //   src: url("./fonts/helvetica.otf") format("opentype");
// // }
