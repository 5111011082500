.container {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}
.footer {
  flex: 1;
  background-color: rgba($color: #ffffff, $alpha: 0.25);
  backdrop-filter: blur(10px);
  display: flex;
  border-radius: 24px;
  align-items: center;
  padding: 12px 22px;
  justify-content: space-between;
  z-index: 10;
}
