$padding-top-header: 0px;
.searchContainer {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // max-height: 100%;
  // position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  // padding-top: $padding-top-header;

  // &.app {
  //   padding-top: 42px;
  // }
  z-index: 1000;
  .back {
    width: 0px;
    overflow: hidden;
    transition: 0.6s;
    color: #1f2029;
    display: flex;
    justify-content: flex-start;
    &.active {
      // min-width: 40px;
      width: 40px;
    }
  }

  .searchSuggestionsContainer {
    height: 0px;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 1;
  }
  &.full {
    height: 100vh;
    .logoHeader {
      height: 0px;
    }
    .back {
      width: 40px;
    }
    .searchSuggestionsContainer {
      height: 100%;
      animation-name: show;
      animation-duration: 0.6s;
      animation-fill-mode: forwards;
      // animation-delay: 0.2s;
      overflow: auto;
    }
  }
}

.inputContainer {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  // background-color: #ffffff;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
    max-height: 0%;
  }
  to {
    opacity: 1;
    max-height: 100%;
  }
}

.marginFixer {
  margin-top: 120px;
}

@keyframes sticky {
  from {
    box-shadow: none;
    border-bottom: thin solid transparent;
  }
  to {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom: thin solid #e0e0e0;
  }
}

.desktopHeader {
  &.sticky {
    animation-name: sticky;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  transition: 0.2s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 24px 60px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
}

.headerLeft {
  display: flex;
  align-items: center;
  // gap: 32px;
  flex: 1;
}

.navigation {
  display: flex;
  gap: 24px;
}

.navLink {
  text-decoration: none;
  font-size: 14px;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}

.searchWrapper {
  flex: 1;
  min-width: 600px;
  max-width: 800px;
  margin: 0 32px;

  :global {
    .search-input {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.2);
      color: white;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.headerRight {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.iconButton {
  background: none;
  border: none;
  color: white;
  padding: 8px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}

.profileSection {
  display: flex;
  align-items: center;
  gap: 4px;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.dropdownButton {
  background: none;
  border: none;
  color: white;
  padding: 4px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}

.logoHeader {
  // background-color: #ffffff;
  transition: 0.6s;
  font-size: 30px;
  font-weight: 400;
  font-family: "Cormorant Garamond";
  min-height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
