.footer {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 62px;
  // padding: 0 20px;
  align-items: center;
  z-index: 100;
  width: 100vw;
  justify-content: space-around;
  // border-top: thin solid #ececf0;
  // background-color: #000000;
  // background-color: #ffffff;
  transition: 0.2s;
  // z-index: 10000;
  // border-top: thin solid #363636;
  // --background-base: #121212;
  // --background-highlight: #1a1a1a;
  // --background-press: #000;
  // --background-elevated-base: #242424;
  // --background-elevated-highlight: #2a2a2a;
  // --background-elevated-press: #000;
  // --background-tinted-base: hsla(0, 0%, 100%, 0.07);
  // --background-tinted-highlight: hsla(0, 0%, 100%, 0.1);
  // --background-tinted-press: hsla(0, 0%, 100%, 0.04);
  .footerContainerFloat {
    background-color: rgba($color: #ffffff, $alpha: 0.25);
    backdrop-filter: blur(10px);
    display: flex;
    border-radius: 24px;
    align-items: center;
    padding: 8px;
  }
  .nav {
    // padding: 5px;
    min-width: 65px;
    max-width: 65px;
    // height: 65px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    .innerContainer {
      border-radius: 50%;
      // flex: 1;
      padding: 10px;
      display: flex;
      gap: 4px;
      color: #808085;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: 0.2s;
      .icon {
        // color: #b3b3b3;
        width: 24px;
        height: 24px;
        &.withFill {
          path {
            fill: #1f2029;
          }
        }

        &.withColor {
          path {
            color: #1f2029;
          }
        }
      }
      &.active {
        // color: #ffffff;
        color: #1f2029;
        background-color: #ffffff;

        // background-color: #ffffff;
        span {
          color: #1f2029;
        }
        .icon {
          &.withFill {
            path {
              fill: #1f2029;
            }
          }
          &.withColor {
            path {
              color: #1f2029;
            }
          }
        }
      }
    }
    span {
      font-size: 10px;
      font-weight: 300;
    }
  }
  &.app {
    height: 84px;
    // background-color: #b3b3b3;
    padding-bottom: 22px;
    .nav {
      // padding: 10px;
    }
  }
}

.relativeController {
  position: relative;
  width: 64px;
  height: 64px;
  .chatBtn {
    bottom: 16px;
    // left: -32px;
    position: absolute;
    width: 64px;
    height: 64px;
    // transform: translateY(100%);
    display: flex;
    .gradient {
      width: 100%;
      height: 100%;
      background: rgb(244, 116, 193);
      background: linear-gradient(
        167deg,
        rgba(244, 116, 193, 1) 0%,
        rgba(228, 254, 159, 1) 50%,
        rgba(63, 49, 224, 1) 100%,
        rgba(0, 0, 0, 0) 100%
      );
      border-radius: 50%;
      padding: 4px;
      .bgBtn {
        width: 100%;
        height: 100%;
        background: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        path {
          fill: #1f2029;
        }
      }
    }
  }
}
