.alignCenter {
  display: flex;
  align-items: center;
}

.alignEnd {
  display: flex;
  align-items: flex-end;
}
.alignStart {
  align-self: baseline;
}
.justifyEnd {
  display: flex;
  justify-content: flex-end;
}
.justifyCenter {
  display: flex;
  justify-content: center;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
}
.flex1 {
  display: flex;
  flex: 1;
}
.column {
  display: flex;
  flex-direction: column;
}
.centerAll {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex {
  display: flex;
}
.wrap {
  flex-wrap: wrap;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  &.md {
    width: 20px;
    height: 20px;
  }
  &.lg {
    width: 24px;
    height: 24px;
  }

  &.withFill {
    &.grey {
      path {
        fill: #4a4a4a;
      }
    }
    &.light {
      path {
        fill: #ffffff;
      }
    }
    &.dark {
      path {
        fill: #2d2d2d;
      }
    }
  }
  &.withColor {
    &.light {
      path {
        color: #ffffff;
      }
    }
    &.dark {
      path {
        color: #2d2d2d;
      }
    }
  }
  &.withStroke {
    &.light {
      path {
        stroke: #ffffff;
      }
    }
    &.dark {
      path {
        stroke: #2d2d2d;
      }
    }
  }
}

.gapSm {
  gap: 4px;
}
.gapMd {
  gap: 8px;
}
.gapLg {
  gap: 16px;
}
.gapXlg {
  gap: 32px;
}

.paddingSm {
  padding: 6px;
}
.paddingMd {
  padding: 10px;
}
.paddingLg {
  padding: 16px;
}
.pointer {
  cursor: pointer;
}

.scrollAuto {
  overflow: auto;
}

.overflowHidden {
  overflow: hidden;
}

.weight {
  font-weight: 500;
}
.weight600 {
  font-weight: 600;
}

.link {
  text-decoration: underline;
  font-weight: 300;
  cursor: pointer;
  &:active {
    text-decoration: underline;
  }
}

.overflowX {
  overflow-x: auto;
}

.inputLabel {
  font-size: 14px;
  font-weight: 400;
  padding-inline-start: 6px;
  padding-bottom: 6px;
}

.mobile {
  max-width: 550px;
  align-self: center;
  align-items: center;
}

.floatController {
  position: relative;
}

.spaceDownMd {
  margin-bottom: 10px;
}
.spaceDownLg {
  margin-bottom: 20px;
}

.underline {
  text-decoration: underline;
}

.sideMargin {
  margin-inline-start: 16px;
  margin-inline-end: 16px;
}
.verticalMargin {
  margin-top: 16px;
  margin-bottom: 16px;
}

.appTopPadding {
  padding-top: 42px;
}

.scrollerContainer {
  overflow: auto;
  flex: 1;
}

.iconFont {
  path {
    fill: #1f2029;
  }
}
.mobileFooterSpacer {
  padding-top: 22px;
}

.small-text {
  font-size: 12px;
  color: #797979;
}

.relative {
  position: relative;
}

.stickyHeader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  display: none;
  z-index: 100;
  opacity: 0;
  animation-duration: 0.3s;
  transform: translateY(-50%);
  &.show {
    display: flex;
    flex-direction: column;
    animation-name: show;
    animation-fill-mode: forwards;
  }
  &.hide {
    animation-name: show;
    animation-direction: reverse;
  }
  @keyframes show {
    from {
      opacity: 0;
      transform: translateY(-50%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
}

.subTitle {
  font-size: 13px;
  font-weight: 300;
  color: var(--font-color-secondary);
  line-height: 1.5;
  &.center {
    text-align: center;
  }
}
