@import "./mixins";
* {
  box-sizing: border-box;
}

html {
  line-height: 1.18;
  -webkit-text-size-adjust: 100%;
}

body,
#root {
  margin: 0;
  height: 100dvh;
  width: 100dvw;
  color: #2d2d2d;
  font-weight: 400;
  // background-color: #000000;
  background-color: #f9f9f9;
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  * {
    ::-webkit-scrollbar {
      display: none;
    }
  }
}

section,
header,
main,
footer {
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1;
}

h4,
h5 {
  font-weight: 500;
}

h1,
h2 {
  font-weight: 400;
}

h3 {
  font-size: 18px;
  font-weight: 400;
}

h1,
h2,
h3 {
  font-family: var(--font-editorial);
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button {
  outline: none;
  border: none;
  cursor: pointer;
}
textarea {
  overflow: auto;
  resize: none;
}

img {
  max-width: 100%;
  max-height: 100%;

  border-style: none;
  object-fit: contain;
  object-position: center;
}
progress {
  vertical-align: baseline;
}

p {
  margin: 0 0 1em;
  max-width: 40ch;
}

a {
  text-decoration: none;
  color: inherit; // here you can put the link color
  &:hover {
    text-decoration: none;
  }
  @include hover-supported {
    &:hover {
      text-decoration: underline;
    }
  }
}

.main-content {
  // display: flex;
  // flex-direction: column;
  // flex: 1;
  overflow-x: hidden;
  transition: transform 0.5s;
  flex: 1;
  // padding-bottom: 54px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 768px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}

a,
button,
input,
textarea {
  -webkit-tap-highlight-color: transparent;
}
a:active,
a:focus,
button:active,
button:focus {
  outline: none;
  border: none;
  background-color: transparent;
  /* Add more style adjustments as needed */
}
* {
  -webkit-tap-highlight-color: transparent;
}
